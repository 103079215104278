<div class="container-fluid p-0">
    <!-- <nav class="cl-navbar">
      <div class="navbar-header">
        <a class="navbar-brand" routerLink="/inicio">
          <img alt="Brand" src="/assets/webflow_img/logo.svg" height="32">
        </a>
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
          =
        </button>
      </div>
      <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul class="nav navbar-nav navbar-right">
          <li><a href="https://www.contalink.com/precios">Precios</a></li>
          <li><a href="https://app.contalink.com">Ingresar</a></li>
          <li><a href="https://www.contalink.com/contact" class="contact-btn">Contacto</a></li>
        </ul>
      </div>
    </nav> -->
    <router-outlet></router-outlet>
    
    <footer class="footer">
      <div class="socials">
        <div class="container">
          <div class="icons">
            <a href="https://www.contalink.com?utm_source=Contalink&utm_medium=Contalink-One&utm_campaign=Footer" target="_blank">
              <img src="assets/contalink-logo.png" alt="Logo de Contalink" loading="lazy" class="img-fluid">
            </a>
            <div class="social-icons">
              <a href="https://www.instagram.com/contalinkapp/" target="_blank" rel="noopener noreferrer">
                <img src="assets/iconos/icon-instagram.png" alt="Icono de Instagram" loading="lazy">
              </a>
              <a href="https://www.facebook.com/contalink/" target="_blank" rel="noopener noreferrer">
                <img src="assets/iconos/icon-facebook.png" alt="Icono de facebook" loading="lazy">
              </a>
              <a href="https://www.linkedin.com/company/contalink/" target="_blank" rel="noopener noreferrer">
                <img src="assets/iconos/icon-linkedin.png" alt="Icono de linkedin" loading="lazy">
              </a>
              <a href="https://www.youtube.com/@contalink9732" target="_blank" rel="noopener noreferrer">
                <img src="assets/iconos/icon-youtube.png" alt="Icono de youtube" loading="lazy">
              </a>
            </div>
            <p class="text">
              Calle Cocoyoc 3449 , Col. Colonial La Silla, CP 64860 , Monterrey, NL. Tel: (81) 1910 7569
            </p>
          </div>
        </div>
      </div>
      <div class="rights">
        <div class="container">
          <p class="text">
            © 2022 Contalink. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  </div>
  <div class="modal fade bs-example-modal-lg" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
          <div>
            <iframe width="100%" height="600" src=""></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AttributionsService {

  constructor(
    private http: HttpClient
  ) { }

  public registerAttributions(
    register: {
      application: string,
      data: any
    }
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders().append('Authorization', environment.ATTRIBUTIONS_API_KEY)

    return this.http.post<any>(
      `${environment.ATTRIBUTIONS_API_URL}/attributions`,
      register,
      { headers }
    )
  }
}

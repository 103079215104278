import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SendPipedriveDataService {


  private url: string = 'https://tepgpuydbg.execute-api.us-east-1.amazonaws.com/PROD/cl-pipedrive-management'
  private api_token: string = '4f7321a8a59d6a8c2e9e0d88b3ce3e6cfe6791b3cf4be3e70ddc1a0b21c9f2e2'

  constructor(private http: HttpClient) {

  }

  public pipeDriveData(signupData: SignupData): Observable<any> {

    let headers: HttpHeaders = new HttpHeaders().set('X-Api-Key', this.api_token);

    return this.http.post(this.url, signupData, {
      headers: headers
    });
  }
}
export interface SignupData {
  name: string,
  email: string,
  phone: string,
  event: string,
  utm_medium: string,
  utm_source: string,
  utm_campaign: string,
  first_visit_date: string,
  first_utm: string
}
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SendSignupDataService} from '../../services/send-signup-data.service';
import { SendPipedriveDataService} from '../../services/send-pipedrive-data.service';
import { NotifierService } from "angular-notifier";
import { Router } from '@angular/router';
import { Data } from "../../providers/data/data";
import { NgxSpinnerService } from "ngx-spinner";
import { AttributionsService } from 'src/app/services/attributions.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-confirm_phone',
  templateUrl: './confirm_phone.component.html',
  styleUrls: ['./confirm_phone.component.css'],
  providers: [SendSignupDataService, SendPipedriveDataService]
})
export class ConfirmPhoneComponent implements OnInit {

  fieldTextType: boolean = false;
  signupRequest: object;

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  public confirmPhoneForm: FormGroup = new FormGroup({
    confirmation_code: new FormControl('', [Validators.required, Validators.pattern("^.{6}$")]),
  })

  public is_valid: boolean = true;
  private readonly notifier: NotifierService;

  disabled_button: boolean = false;

  constructor(public sendSignupData: SendSignupDataService,
    public sendPipedriveData: SendPipedriveDataService,
    notifierService: NotifierService,
    private data: Data,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cookieService: CookieService,
    private attributionService: AttributionsService 
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
  }

  async confirmPhone() {
    this.spinner.show();
    this.disabled_button = true;
    this.is_valid = this.confirmPhoneForm.valid;

    if (this.is_valid) {

      let phone_register_response: any = await this.sendSignupData.signupCustomer({
        name: this.data.storage.name,
        email: this.data.storage.email,
        userpass: this.data.storage.userpass,
        phone: this.data.storage.phone,
        razon_social: this.data.storage.razon_social,
        rfc: this.data.storage.rfc,
        type_client: this.data.storage.type_client,
        accountants_number: this.data.storage.accountants_number,
        customer_source: this.data.storage.customer_source,
        confirmationcode: this.confirmPhoneFormControl.confirmation_code.value
      }).toPromise();

      let pipedrive_data: any = await this.sendPipedriveData.pipeDriveData({
        name: this.data.storage.name,
        email: this.data.storage.email,
        phone: this.data.storage.phone,
        event: 'Registro Lite' ,
        utm_medium: this.getCookie('utm_medium'),
        utm_source: this.getCookie('utm_source'),
        utm_campaign: this.getCookie('utm_campaign'),
        first_visit_date: this.getCookie('first_visit_date'),
        first_utm: this.getCookie('first_utm'),
      }).toPromise();
      // Hide console.log
      // console.log(phone_register_response);

      if (phone_register_response.status == 1){
        this.notifier.notify("success", "Registro exitoso en Contalink.");
        // this.router.navigate(['success']);
        this.confirmPhoneForm.reset();
        
        // Here should send UTM Cookies information
        this.sendUTMsCookies()
      }
      else
      {
        this.spinner.hide();
        this.notifier.notify("error", phone_register_response.message);
        this.disabled_button = false;
        this.confirmPhoneForm.reset();
      }

    } else {
      this.spinner.hide();
      if (this.confirmPhoneForm.get('confirmation_code').invalid == true) {
        this.notifier.notify("error", "El código de confirmación debe ser a 6 dígitos");
      }
      this.disabled_button = false;
    }
  }

  getCookie(name: string) {
    return this.cookieService.get(name);
  }

  sendUTMsCookies() {
    let register = {
      application: "lite",
      data: {
        utm_medium: this.getCookie('utm_medium'),
        utm_source: this.getCookie('utm_source'),
        utm_campaign: this.getCookie('utm_campaign'),
        first_visit_date: this.getCookie('first_visit_date'),
        first_utm: this.getCookie('first_utm'),
        client: {
          name: this.data.storage.name,
          email: this.data.storage.email,
          phone: this.data.storage.phone,
          rfc: this.data.storage.rfc,
        }
      }
    }

    let $response: Observable<any> = this.attributionService.registerAttributions(register)

    $response.subscribe((res) => {
      if (res.attributions.success) {
        this.redirectToThankYouPage()
      } else {
        this.redirectToThankYouPage()
      }
    })
  }

  redirectToThankYouPage() {
    // We make sure we are on browser environment and redirects URL.
    try {
      if (window !== undefined) {
        window.location.replace('https://www.contalink.com/gracias?message=Lite')
      }
    } catch (error) {
      // If external redirect fails then should fallback to old confirmation page.
      this.router.navigate(['success']);
    } 
  }

  get confirmPhoneFormControl() {
    return this.confirmPhoneForm.controls;
  }

}

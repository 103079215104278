import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ConfirmPhoneComponent } from './pages/confirm_phone/confirm_phone.component';
import { SuccessComponent } from './pages/success/success.component';

import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {SendSignupDataService } from './services/send-signup-data.service';

import { environment } from '../environments/environment';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { MainComponent } from './main/main.component';

import { PixelModule } from 'ngx-pixel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { Data } from "./providers/data/data";

import { NgxSpinnerModule } from "ngx-spinner";



export const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    ConfirmPhoneComponent,
    SuccessComponent,
    MainComponent   
  ],
  imports: [
    BrowserModule,
    PixelModule.forRoot({ enabled: true, pixelId: '553320291959158' }),
    AppRoutingModule,
    HttpClientModule,
        NotifierModule.withConfig(customNotifierOptions),
    ReactiveFormsModule,
        BrowserAnimationsModule,
        MatIconModule,
        NgxSpinnerModule
  ],
  providers: [Data],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { ConfirmPhoneComponent } from './pages/confirm_phone/confirm_phone.component';
import { SuccessComponent } from './pages/success/success.component';
import { Router } from '@angular/router';
import { MainComponent } from './main/main.component';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [{
      path: '',
      component: ContactComponent
    },
    {
      path: '',
      redirectTo: '',
      pathMatch: 'full'
    }, {
      path: 'contacto',
      component: ContactComponent
    },
    {
      path: 'confirmar_telefono',
      component: ConfirmPhoneComponent
    },
    {
      path: 'success',
      component: SuccessComponent
    }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.errorHandler = (error: any) => {
      this.router.navigate(['inicio']); // or redirect to default route
    }
  }

}

<notifier-container></notifier-container>
<ngx-spinner></ngx-spinner>


<section class="phone-section">
  <div class="navbar">
  </div>

  <div class="center-elements">
    <div class="container">
      <div class="logo-confirm-phone">
        <a routerLink="/">
          <img src="assets/contalink-lite-logo.png"
          alt="Contalink Lite" loading="lazy" class="img-fluid">
        </a>
      </div>
      <div class="row" [formGroup]="confirmPhoneForm">
        <div class="contact-form-bckgd">
          <p class="contact-bg-img-text">Confirma tu celular</p>
            <div class="contact-form form-inline" role="form">
              <div class="row">
                <p for="confirmation_code" class="label-contact-field">Ingresa el código que hemos enviado vía Whatsapp a tu celular:</p>
                <br>
                <input class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-field" formControlName="confirmation_code"
                  [ngClass]="{'': confirmPhoneFormControl.confirmation_code.valid && is_valid, 'required': confirmPhoneFormControl.confirmation_code.invalid && !is_valid }">
              </div>
              <div>
                <div class="button-container">
                  <button id="confirm_phone_form" class="button-primary" (click)="confirmPhone()"
                    [disabled]="disabled_button">Confirmar</button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
<notifier-container></notifier-container>
<ngx-spinner></ngx-spinner>

<div>
  <div class="hero">
    <!--
    <div class="lite-logo">
      <img src="assets/contalink-lite-logo-black.png" alt="Contalink Lite" />
    </div>-->

    <div class="login-form-container">
      <div class="titles p-3" style="text-align: left;">
        <div style="position:absolute;left: 100px;top: 200px;z-index: 100;">
          <img
            src="assets/download-icon.png"
            alt="Lista de comparación"
            loading="lazy"
          />
        </div>
        <h1 class="subtitle">
          <span class="text-yellow-contalink-primary" style="color: #FFC200;font-family: Lexend;font-size: 54px;font-style: normal;font-weight: 800;line-height: normal;">
            Descarga gratis 
            <br />los CFDIs
          </span>
          <span style="color: #FFF;font-family: Lexend;font-size: 54px;font-style: normal;font-weight: 600;line-height: normal;">
          de tus
          <br />
          clientes en la nube
          </span>
        </h1>
        <h2 class="text">
          <span style="color: #FFF;font-family: Lexend;font-size: 23px;font-style: normal;font-weight: 500;line-height: normal;">
            Almacenamiento
          </span>
          <span style="color: #FFF;font-family: Lexend;font-size: 23px;font-style: normal;font-weight: 800;line-height: normal;">
            ilimitado de CFDI's, descarga <br /> automática del SAT
          </span>
          <span style="color: #FFF;font-family: Lexend;font-size: 23px;font-style: normal;font-weight: 500;line-height: normal;">
            y exportación de
          </span>
          <span style="color: #FFF;font-family: Lexend;font-size: 23px;font-style: normal;font-weight: 800;line-height: normal;">
            reportes <br />
          </span>
          <span style="color: #FFF;font-family: Lexend;font-size: 23px;font-style: normal;font-weight: 500;line-height: normal;">
            para tus clientes
          </span>
        </h2>
      </div>
      <div class="">
        <div class="row" [formGroup]="signupForm">
          <div class="signup-form shadow contact-form-bckgd">
            <div class="col-12">
              <span id="contalink-lite-logo-black"><img src="assets/contalink-lite-logo-black.png" alt="Contalink Lite" /></span>
              <p class="contact-bg-img-text">Regístrate ahora</p>
              <div class="contact-form form-inline" role="form">
                <div class="row">
                  <label for="name" class="label-contact-field line-clamp"
                    >Nombre completo *</label
                  >

                  <input
                    class="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-field"
                    id="name"
                    formControlName="name"
                    [ngClass]="{
                      '': signupFormControl.name.valid && is_valid,
                      required: signupFormControl.name.invalid && !is_valid
                    }"
                  />
                </div>
                <div class="row">
                  <label for="user_email" class="label-contact-field line-clamp"
                    >Correo electrónico *</label
                  >

                  <input
                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field"
                    id="user_email"
                    type="text"
                    formControlName="email"
                    [ngClass]="{
                      '': signupFormControl.email.valid && is_valid,
                      required: signupFormControl.email.invalid && !is_valid
                    }"
                  />
                </div>
                <div class="row p-0">
                  <div
                    class="col-12 col-md-6 p-0"
                    style="padding: 0 !important"
                  >
                    <div class="row p-0">
                      <label for="client_phone" class="label-contact-field line-clamp"
                        >Celular *</label
                      >

                      <input
                        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field"
                        type="tel"
                        id="client_phone"
                        formControlName="phone"
                        [ngClass]="{
                          '': signupFormControl.phone.valid && is_valid,
                          required: signupFormControl.phone.invalid && !is_valid
                        }"
                      />
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6 p-0"
                    style="padding: 0 0 0 1rem !important"
                  >
                    <div class="row p-0">
                      <label for="user_password" class="label-contact-field line-clamp"
                        >Password *</label
                      >

                      <input
                        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pwd-field"
                        [type]="fieldTextType ? 'text' : 'password'"
                        id="user_password"
                        formControlName="userpass"
                        [ngClass]="{
                          '': signupFormControl.userpass.valid && is_valid,
                          required:
                            signupFormControl.userpass.invalid && !is_valid
                        }"
                      />
                      <mat-icon
                        class="password-toggle"
                        (click)="toggleFieldTextType()"
                        >{{
                          fieldTextType ? "visibility" : "visibility_off"
                        }}</mat-icon
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6" style="padding: 0 !important">
                    <div class="row">
                      <label for="razon_social" class="label-contact-field line-clamp"
                        >Razón Social a descargar *
                      </label>
                      <input
                        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field"
                        type="text"
                        id="razon_social"
                        formControlName="razon_social"
                        [ngClass]="{
                          '': signupFormControl.razon_social.valid && is_valid,
                          required:
                            signupFormControl.razon_social.invalid && !is_valid
                        }"
                      />
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6"
                    style="padding: 0 0 0 1rem !important"
                  >
                    <div class="row">
                      <label for="rfc_client" class="label-contact-field line-clamp"
                        >RFC a descargar *</label
                      >
                      <input
                        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field"
                        type="text"
                        formControlName="rfc"
                        id="rfc_client"
                        [ngClass]="{
                          '': signupFormControl.rfc.valid && is_valid,
                          required: signupFormControl.rfc.invalid && !is_valid
                        }"
                      />

                      <label for="rfc_client" class="label-contact-field-small"
                        >** Esta será la primera empresa a descargar. Después
                        podrás agregar tantas como necesites</label
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="label-contact-field line-clamp" for="type_client"
                    >¿Que opción te describe mejor? *</label
                  >

                  <select
                    name="type_client"
                    id="type_client"
                    class="custom-select w-100"
                  >
                    <optgroup class="label-contact-field line-clamp">
                      <option value=""></option>
                      <option value="DC">
                        Soy un contador dentro de un despacho contable
                      </option>
                      <option value="DCPYME">
                        Trabajo en el departamento contable de una empresa
                      </option>
                      <option value="PYME">Soy una PYME</option>
                      <option value="PI">
                        Soy un profesionista independiente
                      </option>
                    </optgroup>
                    >
                  </select>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6" style="padding: 0 !important">
                    <div class="row">
                      <label
                        for="accountants_number"
                        class="label-contact-field"
                        >Número de contadores o auxiliares, incluyendo a usted.
                        *</label
                      >
                      <input
                        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-field"
                        type="number"
                        formControlName="accountants_number"
                        [ngClass]="{
                          '':
                            signupFormControl.accountants_number.valid &&
                            is_valid,
                          required:
                            signupFormControl.accountants_number.invalid &&
                            !is_valid
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6" style="padding: 0 0 0 1rem !important">
                    <div class="row">
                      <label class="label-contact-field" for="customer_source"
                        >¿Cómo te enteraste de nosotros? *</label
                      >
                      <br>
                      <br>
                      <select
                        name="customer_source"
                        id="customer_source"
                        class="custom-select"
                      >
                        <optgroup class="label-contact-field line-clamp">
                          <option value=""></option>
                          <option value="GOOGLESEARCH">
                            Búsqueda en Google
                          </option>
                          <option value="FB ADS">Anuncio en Facebook</option>
                          <option value="GOOGLE ADS">
                            Anuncio en otro sitio Web
                          </option>
                          <option value="IMCP">IMCP</option>
                          <option value="EL CONTRIBUYENTE">
                            El Contribuyente
                          </option>
                          <option value="FORBES">Forbes</option>
                          <option value="REFERENCIA">
                            Referencia de conocido / colega
                          </option>
                          <option value="OTRO">Otro</option>
                        </optgroup>
                        >
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="send_button_container">
                      <button
                        id="send_contact_form"
                        class="contact-button"
                        (click)="enviar()"
                        [disabled]="disabled_button"
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mt-10">
                  <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                    <a class="privacy-text" routerLink="/aviso-de-privacidad"
                      >Aviso de privacidad</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </div>

  <div class="form-container" id="registro"></div>

  <div class="benefits">
    <div class="container" style="text-align: center;">
      <span style="color: #2761AD;text-align: center;font-family: Lexend;font-size: 32px;font-style: normal;font-weight: 700;line-height: normal;">
        Una alternativa gratuita y mucho más amigable <br>
        a la descarga de CFDIs del SAT, OneFacture o AdminXML <br>
      </span>
      <span style="color: #050029;font-family: Lexend;font-size: 34px;font-style: normal;font-weight: 500;line-height: normal;">
        Sin limitantes y sin trucos; todo incluido y sin futuros cobros extras.<br>
      </span>
      <br><br><br>
      <div>
        <img
          src="assets/comparison-list.png" width="1163px"
          alt="Lista de comparación"
          loading="lazy"
        />
      </div>
    </div>
  </div>

  <div class="free">
      <div>
        <img
          src="assets/instalable-vs-nube.png" width="100%"
          alt="Lista de comparación"
          loading="lazy"
        />
      </div>      
  </div>

</div>

<section></section>

<notifier-container></notifier-container>
<section class="success-section">
  <div class="row" [formGroup]="successForm">
    <div>
      <div style="text-align: center;">
        <div class="logo-success">
          <a routerLink="/">
            <img src="assets/contalink-lite-logo.png"
            alt="Contalink Lite" loading="lazy" class="img-fluid">
          </a>
        </div>
        <p class="success-bg-img-text">¡Te damos las bienvenida!</p>
        <div class="success-form form-inline" role="form">
          <div class="row">
            <br>
            <span for="confirmation_code" class="label-success-field" style="color: #FFF;text-align: center;text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);font-family: Lexend;font-size: 35px;font-style: normal;font-weight: 500;line-height: normal;">
              Ya puedes usar
            </span>
            <span for="confirmation_code" class="label-success-field" style="color: #FFF;font-family: Lexend;font-size: 35px;font-style: normal;font-weight: 700;line-height: normal;">
              Contalink Lite
            </span>
            <br><br><br>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <button id="confirm_phone_form" class="success-button" (click)="goToContalink()"
                [disabled]="disabled_button">Iniciar sesión en Contalink</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>